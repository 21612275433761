"use client";

import React from "react";
import * as Icon from "react-feather";
import Link from "next/link";

const Features = () => {
  return (
    <>
      <div className="boxes-area">
        <div className="container">
          <div className="row justify-content-center">

          <div
              className="col-lg-3 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-box">
                <div className="icon">
                  <Icon.Smartphone />
                </div>

                <h3>
                  <Link href="/features/feature-details/" >
                    Mobile App Development
                  </Link>
                </h3>

                <p>
                Build performant, scalable, and secure mobile applications for iOS and Android devices.
                </p>
              </div>
            </div>


          <div
              className="col-lg-3 col-md-6"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-box bg-f78acb">
                <div className="icon">
                  <Icon.Code />
                </div>

                <h3>
                  <Link href="/features/feature-details/" >
                    Custom Software Development
                  </Link>
                </h3>

                <p>
                Create custom software tailored for your unique needs, including front-end, and core back-end technology.
                </p>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-box bg-c679e3">
                <div className="icon">
                  <Icon.UploadCloud />
                </div>

                <h3>
                  <Link href="/features/feature-details/" >
                    Cloud & Advanced Technologies
                  </Link>
                </h3>

                <p>
                  Build performant & scalable Cloud Native Solutions. Leverage AI and ML technologies to enhance automation, productivity, and decision-making processes.
                </p>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-box bg-eb6b3d">
                <div className="icon">
                  <Icon.CheckCircle />
                </div>

                <h3>
                  <Link href="/features/feature-details/" >
                    QA & Testing
                  </Link>
                </h3>

                <p>
                Make your technology bulletproof, with manual and automated testing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
