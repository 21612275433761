"use client";

import React from "react";
import Link from "next/link";
import * as Icon from "react-feather";

const WhyChooseUs = () => {
  return (
    <>
      <div className="features-area pt-80 pb-50 bg-f7fafd">
        <div className="container">
          <div className="section-title">
            <h2>Why Choose Us</h2>
            <div className="bar"></div>
          </div>

          <div className="row">
            <div
              className="col-lg-6 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-features">
                <div className="icon">
                  <Icon.Settings />
                </div>
                <h3>
                  <Link href="/features/feature-details/" >
                    Experts
                  </Link>
                </h3>
                <p>
                Experienced and dedicated engineers with higher business acumen to meet your project demands.
                </p>
              </div>
            </div>

            <div
              className="col-lg-6 col-md-6"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-features">
                <div className="icon">
                  <Icon.Mail />
                </div>
                <h3>
                  <Link href="/features/feature-details/" >
                    Right Team
                  </Link>
                </h3>
                <p>
                From solo engineers to multi-team engagements. Flexible project management options.
                </p>
              </div>
            </div>

            <div
              className="col-lg-6 col-md-6"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-features">
                <div className="icon bg-c679e3">
                  <Icon.Grid />
                </div>
                <h3>
                  <Link href="/features/feature-details/" >
                    One Stop
                  </Link>
                </h3>
                <p>
                For all your unique requirements, be it individual resources, dedicated teams or turnkey projects – we ensure remarkable results.
                </p>
              </div>
            </div>

            <div
              className="col-lg-6 col-md-6"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-features">
                <div className="icon bg-c679e3">
                  <Icon.Info />
                </div>
                <h3>
                  <Link href="/features/feature-details/" >
                    Quality & Timely
                  </Link>
                </h3>
                <p>
                Quality-driven custom software projects and services are considered as best business practices for faster and on-time delivery, proven results, and innovation
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default WhyChooseUs;
