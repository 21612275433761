"use client";

import React from "react";

import DesignAndDevelopment from "./DesignAndDevelopment";
import CloudHostingServices from "./CloudHostingServices";
import QATestingAndModernization from "./QATestingAndModernization";

const ServicesArea = () => {
  return (
    <>
      <DesignAndDevelopment />
      <CloudHostingServices />
      <QATestingAndModernization/>
    </> 
  );
};

export default ServicesArea;
