"use client";

import React from "react";
import * as Icon from "react-feather";
import Image from "next/image";

import image from "/public/images/qa-testing-modernization-image/image.png";

const QATestingAndModernization = () => {
  return (
    <>
      <div className="services-area ptb-80">
        <div className="container">
          <div className="row h-100 justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 services-content order-2 order-md-1 order-lg-1">
              <div className="section-title">
                <h2>QA, Testing, Maintenance & Modernization</h2>
                <div className="bar"></div>
                <p>
                  Ensure software quality and performance with thorough QA and
                  testing, while keeping it up-to-date through ongoing technical
                  support and updates.Modernize legacy software and
                  infrastructure to meet current standards, and safeguard
                  digital assets with state-of-the-art cybersecurity services.
                </p>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="box">
                    <Icon.Codepen /> Software Testing & QA
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="box">
                    <Icon.Code /> Maintenance & Support
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="box">
                    <Icon.Server /> Modernization and Migration
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="box">
                    <Icon.Code /> Web Application Development
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 services-content order-1 order-md-2 order-lg-2">
              <Image
                src={image}
                alt="qa-testing-modernization"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="500"
                data-aos-once="true"
              />
            </div>            

          </div>
        </div>
      </div>
    </>
  );
};

export default QATestingAndModernization;
