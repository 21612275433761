"use client";

import React from "react";
import Link from "next/link";
import * as Icon from "react-feather";
import { IconContext } from "react-icons";
import * as FaIcon from "react-icons/fa";
import * as SiIcon from "react-icons/si";
import Marquee from "react-fast-marquee";

const TechStack = () => {
  return (
    <>
      <div className="features-area pt-80 pb-50 bg-f7fafd">
        <div className="container">
          <div className="section-title">
            <h2>We cover your tech stack</h2>
            <div className="bar"></div>
            <p>Our team has expertise in almost every technology.
            </p>
          </div>

          <div className="row">
            <IconContext.Provider value={{ className: "tech-icon" }}>
                <Marquee pauseOnHover gradient >
                    <FaIcon.FaReact />
                    <FaIcon.FaAngular />
                    <FaIcon.FaVuejs />
                    <FaIcon.FaNodeJs/>
                    <FaIcon.FaJava/>
                    <FaIcon.FaSwift/>
                    <SiIcon.SiSpringboot/>
                    <SiIcon.SiSpring/>
                    <SiIcon.SiTypescript/>
                    <SiIcon.SiJavascript/>
                    <SiIcon.SiPython/>
                    <SiIcon.SiDotnet/>
                    <SiIcon.SiAndroid/>
                    <SiIcon.SiPhp/>
                    <SiIcon.SiRubyonrails/>
                    <SiIcon.SiMicrosoftazure/>
                    <SiIcon.SiAzuredevops/>
                    <SiIcon.SiAmazonwebservices/>
                    <SiIcon.SiDocker/>
                    <SiIcon.SiKubernetes/>
                    <SiIcon.SiFlutter/>
                    <SiIcon.SiGraphql/>
                    <SiIcon.SiApachekafka/>
                    <SiIcon.SiMysql/>
                    <SiIcon.SiPostgresql/>
                    <SiIcon.SiMongodb/>
                    <SiIcon.SiSelenium/>
                    <SiIcon.SiTerraform/>
                </Marquee>
            </IconContext.Provider>
          </div>
        </div>
      </div>
    </>
  );
};

export default TechStack;
