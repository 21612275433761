"use client";

import React from "react";
import Link from "next/link";
import * as Icon from "react-feather";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const OurProcess = () => {
  return (
    <>
      <div className="features-area pt-80 pb-50 bg-f7fafd">
        <div className="container">
          <div className="section-title">
            <h2>Our Process</h2>
            <div className="bar"></div>
          </div>

          <div className="row justify-content-center pb-50">
            <VerticalTimeline animate={false} lineColor="#0e314c">
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="Step 1"
                iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
                icon={<Icon.PhoneCall />}
              >
                <h3 className="vertical-timeline-element-title">
                  Join exploration call.
                </h3>
                <p>
                  Tell us more about your business on a discovery call. We’ll
                  discuss team structure and approach, success criteria,
                  timescale, budget, and required skill sets to see how we can
                  help.
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="Step 2"
                iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
                icon={<Icon.Users />}
              >
                <h3 className="vertical-timeline-element-title">
                  Discuss solution and team structure.
                </h3>
                <p>
                  In a matter of days, we will finalize your project
                  specifications, agree on an engagement model, select and
                  onboard your team.
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="Step 3"
                iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
                icon={<Icon.GitMerge />}
              >
                <h3 className="vertical-timeline-element-title">
                  Get started and track performance.
                </h3>
                <p>
                  Once we’ve agreed on milestones, we’ll immediately get to
                  work. We’ll track progress, report updates, and continuously
                  adapt to your needs.
                </p>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </div>

          <div className="row justify-content-center">
            <div className="d-grid col-3 mx-auto">
              <Link
                href="/contact"
                className="btn btn-primary"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-duration="500"
                data-aos-once="true"
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurProcess;
