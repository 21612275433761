"use client";

import React from "react";
import Marquee from "react-fast-marquee";
import Image from "next/image";
import everi from "/public/images/clients/everi-logo.svg";
import accolite from "/public/images/clients/accolite.webp";
import braghouse from "/public/images/clients/braghousewhite.png";

const OurClients = () => {
  return (
    <>
      <div className="clients-serve-area pt-80 pb-50">
        <div className="container">
          <div className="section-title">
            <h2>Our Happy Clients</h2>
            <div className="bar"></div>
          </div>

          <div className="row">

                <Marquee pauseOnHover >
                  <Image src={everi} alt="everi" width={302} className="client-icon"/>
                  <Image src={accolite} alt="accolite" width={450}  className="client-icon"/>
                  <Image src={braghouse} alt="braghouse" width={150}  className="client-icon"/>
                </Marquee>

          </div>
        </div>
      </div>
    </>
  );
};

export default OurClients;
